#home_book_now{
    position: relative;
    margin-bottom: 30px;
    text-align: center;
    img{
        width: 100%;
        height: 700px;
        user-select: none;
        pointer-events: none;
    }
    a{
        position: absolute;
        bottom: 16%;
        right: 10.5%;
        width: 31%;
        height: 10%;
        border-radius: 25px;
        z-index: 1;
    }
    @media screen and (max-width:992px) {
        margin-bottom: 0;
        img{
            height: auto;
        }
    }
}
#site_big_title{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    @media screen and (max-width:360px) {
        font-size: 24px;
    }
    @media screen and (max-width:320px) {
        font-size: 20px;
    }
}
.home_accordion{
    .accordion-item{
        cursor: pointer;
    }
    .home_accordion_image_text{
        display: flex;
        align-items: center;
        img{
            width: 200px;
            height: 100%;
            border-top-left-radius: 0.375rem;
            border-bottom-left-radius: 0.375rem;
        }
        .accordion-header{
            width: 100%;
            button{
                background-color: #fff;
                color: #26444F;
                font-size: 18px;
                box-shadow: none;
                @media screen and (max-width:375.2px) {
                    font-size: 17px;
                }
                @media screen and (max-width:360px) {
                    font-size: 15px;
                }
            }
        }
        @media screen and (max-width:576px) {
            img{
                width: 150px;
            }
        }
        @media screen and (max-width:320px) {
            img{
                width: 100px;
            }
        }
    }
}