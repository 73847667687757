#header {
  position: fixed;
  background-color: #e9e9e9;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 60px;
  padding: 0 !important;
  z-index: 1001;
}
#header .navbar-brand {
  display: flex;
  align-items: center;
  width: 120px;
  height: 60px;
  margin: 0;
}
#header .navbar-brand img {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1199px) {
  #header .navbar-brand {
    width: 200px;
  }
}
#header #nav_toogler {
  box-shadow: none;
}
#header #nav_toogler:not(.collapsed) {
  background-color: #cfe2ff;
}
#header #basic-nav-dropdown img {
  width: auto;
  height: auto;
}
#header .dropdown-menu {
  background-color: #e9e9e9;
  border: none;
}
#header .dropdown-menu img {
  width: auto;
  height: auto;
}
@media screen and (min-width: 1199px) {
  #header .dropdown-menu {
    margin-left: -50px;
  }
}
@media screen and (min-width: 1199px) {
  #header .header_armenian_font {
    font-size: 14px;
  }
}
#header .nav_links {
  min-height: 60px;
}
#header .nav_links a {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
@media screen and (max-width: 1199px) {
  #header .nav_links {
    padding: 15px 0;
  }
  #header .nav_links a {
    margin-left: 0;
    margin-top: 10px;
  }
}
@media screen and (max-width: 1199px) {
  #header {
    padding: 15px 0;
  }
}
#header * {
  color: #26444F;
}

.navbar-collapse.show {
  padding-bottom: 10px !important;
}

#to_order {
  position: fixed;
  top: 65px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1002;
  background-color: #26444F;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 30px;
  border-radius: 50px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
#to_order a {
  color: #fff;
  font-size: 17px;
  font-family: Georgia, "Times New Roman", Times, serif;
}
@media screen and (max-width: 576px) {
  #to_order {
    top: 12.5px;
    margin-left: 15%;
  }
}
@media screen and (max-width: 375.2px) {
  #to_order {
    padding: 5px 20px;
  }
}
@media screen and (max-width: 320px) {
  #to_order {
    top: 65px;
    margin-left: 0;
  }
}/*# sourceMappingURL=header.css.map */