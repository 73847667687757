.add_review_page {
  text-align: center;
}
.add_review_page h2 {
  color: #26444F;
}
.add_review_page h2 span {
  font-size: 22px;
}
@media screen and (max-width: 320px) {
  .add_review_page h2 span {
    font-size: 20px;
  }
}
.add_review_page textarea {
  width: 50% !important;
  min-height: 80px;
  margin-bottom: 30px;
  padding: 10px;
  border: none;
  border-radius: 7px;
  outline: none;
}
@media screen and (max-width: 768px) {
  .add_review_page textarea {
    width: 80% !important;
  }
}
.add_review_page select {
  width: 100px;
}
.add_review_page button {
  background-color: #26444F;
  margin: 30px 0;
  padding: 5px 15px;
  color: #fff;
  border: 2px solid #26444F;
  border-radius: 7px;
  cursor: pointer;
  transition: all 0.7s ease-in;
}
.add_review_page button:hover {
  background-color: #e9e9e9;
  color: #000;
}
.add_review_page #select_image {
  background-color: #26444F;
  margin: 0 auto;
  margin-bottom: 50px;
  padding: 5px 15px;
  color: #fff;
  border: 2px solid #26444F;
  border-radius: 7px;
  cursor: pointer;
  transition: all 0.7s ease-in;
}
.add_review_page #select_image:hover {
  background-color: #e9e9e9;
  color: #000;
}
.add_review_page #for_image {
  margin: 0 auto;
  padding: 0 10px;
}
.add_review_page #for_image img {
  width: 400px;
  height: 300px;
  border-radius: 7px;
}
@media screen and (max-width: 576px) {
  .add_review_page #for_image img {
    width: 100%;
  }
}
.add_review_page ul {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}/*# sourceMappingURL=add_review.css.map */