h5.mt-5{
    color: #26444F !important;
    text-decoration: underline;
}
.excursion_carousel{
    width: 70%;
    margin: 0 auto;
    img{
        height: 510px;
        border-radius: 7px;
        @media screen and (max-width:992px) {
            height: 400px;
        }
        @media screen and (max-width:556px) {
            height: 250px;
        }
    }
    @media screen and (max-width:992px) {
        width: 100%;
    }
}
.excursion_item{
    border: none !important;
}
.excursion_item_header{
    button{
        background-color: #fff !important;
        box-shadow: none !important;
    }
}
.excursion_item_body{
    background-color: #fff;
    color: #000;
    text-align: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
    .excursion_img{
        img{
            width: 70%;
            border-radius: 7px;
            @media screen and (max-width:992px) {
                width: 100%;
            }
        }
    }
}