#input_map {
  display: flex;
  justify-content: space-between;
  font-family: -apple-system, Helvetica Neue, Helvetica, Arial, sans-serif;
}
#input_map #map_parent {
  width: 50%;
}
#input_map #map_parent #map {
  width: 100%;
  height: 400px;
  outline: none;
  z-index: 0;
}
@media screen and (max-width: 1200px) {
  #input_map #map_parent #map {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 1199px) {
  #input_map {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  #input_map #map_parent {
    width: 100%;
  }
}

.addres_filter {
  width: 49%;
}
.addres_filter div div {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  padding: 10px;
}
.addres_filter input {
  width: 100%;
  height: 40px;
  padding-left: 10px;
  border: none;
  outline: none;
}
.addres_filter .input_clear {
  position: relative;
}
.addres_filter .input_clear .clear_filter {
  position: absolute !important;
  background-color: silver;
  top: 0 !important;
  left: 95.2% !important;
  width: -moz-fit-content;
  width: fit-content;
  max-height: 39.5px;
  height: 100%;
  color: gray;
  cursor: pointer;
}
@media screen and (max-width: 1199px) {
  .addres_filter .input_clear .clear_filter {
    left: 97% !important;
  }
}
@media screen and (max-width: 992px) {
  .addres_filter .input_clear .clear_filter {
    left: 95.5% !important;
  }
}
@media screen and (max-width: 768px) {
  .addres_filter .input_clear .clear_filter {
    left: 94% !important;
  }
}
.addres_filter .input_clear div {
  width: 100%;
}
.addres_filter .input_clear div div {
  min-width: 100% !important;
}
.addres_filter .input_clear .filter_results {
  background-color: #fff;
  cursor: pointer;
  transition: all 0.7s ease;
}
.addres_filter .input_clear .filter_results:hover {
  background-color: silver;
}
@media screen and (max-width: 1199px) {
  .addres_filter {
    width: 100%;
  }
}
.addres_filter .travel_infos {
  margin: 0 auto;
  color: gray;
}
.addres_filter .travel_infos #date_time_travel, .addres_filter .travel_infos #date_time_return {
  background-color: #fff !important;
}
.addres_filter .travel_infos input {
  cursor: pointer;
}
.addres_filter .travel_infos .travel_info_icons {
  position: relative;
  margin-top: 15px;
  cursor: pointer;
}
.addres_filter .travel_infos .travel_info_icons input {
  text-align: center;
}
.addres_filter .travel_infos .travel_info_icons .travel_info_icons_left {
  position: absolute !important;
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  top: 7px !important;
  left: 10px !important;
}
.addres_filter .travel_infos .travel_info_icons .travel_info_icons_left svg {
  margin-right: 10px;
  color: #000;
}
.addres_filter .travel_infos .travel_info_icons .travel_info_icons_right {
  position: absolute !important;
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  top: 5px !important;
  left: 80% !important;
}
.addres_filter .travel_infos .travel_info_icons .travel_info_icons_right span {
  background-color: #26444F;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  color: #fff;
  font-size: 24px;
  border-radius: 50%;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.addres_filter .travel_infos .travel_info_icons .travel_info_icons_right span:first-child {
  margin-right: 10px;
  padding-bottom: 5px;
}
@media screen and (max-width: 576px) {
  .addres_filter .travel_infos .travel_info_icons .travel_info_icons_right {
    left: 70% !important;
  }
}
.addres_filter .travel_infos .child_seats {
  display: flex;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  cursor: pointer;
}
.addres_filter .travel_infos .child_seats div svg {
  color: #000;
}
.addres_filter .travel_infos .child_seats div svg:first-child {
  margin-right: 10px;
}
.addres_filter .travel_infos .child_seats .selected_childs_seats {
  color: #000;
}
.addres_filter .travel_infos .child_seats .selected_childs_seats span {
  margin-left: 5px;
  padding: 5px;
  padding-right: 8px;
  padding-bottom: 8px;
  border: 1px solid silver;
  border-radius: 50px;
}
.addres_filter .travel_infos .child_seats .selected_childs_seats span img {
  width: 25px;
  height: 25px;
}
@media screen and (max-width: 320px) {
  .addres_filter .travel_infos .child_seats .selected_childs_seats span {
    margin-left: 3px;
  }
}
.addres_filter .travel_infos .child_seats_big_box {
  position: fixed !important;
  background-color: rgba(0, 0, 0, 0.5725490196);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0 !important;
  width: 100%;
  height: 100vh !important;
  padding: 0;
  z-index: 1;
}
.addres_filter .travel_infos .child_seats_big_box .child_seats_box {
  background-color: #fff;
  width: 400px;
  height: 300px;
  color: #000;
  padding: 0 25px;
  padding-top: 15px;
  border-radius: 10px;
  z-index: 2;
}
.addres_filter .travel_infos .child_seats_big_box .child_seats_box .child_seats_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}
.addres_filter .travel_infos .child_seats_big_box .child_seats_box .child_seats_item .child_seats_close {
  font-size: 22px;
  padding: 0;
}
.addres_filter .travel_infos .child_seats_big_box .child_seats_box .child_seats_item .child_seats_close:last-child {
  cursor: pointer;
}
.addres_filter .travel_infos .child_seats_big_box .child_seats_box .child_seats_item .child_seats_two_item {
  display: flex;
  align-items: center;
  padding: 0;
}
.addres_filter .travel_infos .child_seats_big_box .child_seats_box .child_seats_item .child_seats_two_item .child_seats_title {
  font-weight: 600;
}
.addres_filter .travel_infos .child_seats_big_box .child_seats_box .child_seats_item .child_seats_two_item .child_seats_buttons {
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
}
.addres_filter .travel_infos .child_seats_big_box .child_seats_box .child_seats_item .child_seats_two_item .child_seats_buttons span {
  background-color: #26444F;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  color: #fff;
  font-size: 24px;
  border-radius: 50%;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.addres_filter .travel_infos .child_seats_big_box .child_seats_box .child_seats_item .child_seats_two_item .child_seats_buttons span:first-child {
  padding-bottom: 5px;
}
.addres_filter .travel_infos .child_seats_big_box .child_seats_box .child_seats_item .child_seats_two_item .child_seats_buttons input {
  width: 50px;
  margin: 0 5px;
  text-align: center;
  padding: 0;
  border: 1px solid gray;
  border-radius: 7px;
  cursor: default;
}
.addres_filter .travel_infos .child_seats_big_box .child_seats_box .child_seats_item .child_seats_two_item:first-child {
  margin-top: 10px;
}
@media screen and (max-width: 768px) {
  .addres_filter .travel_infos .child_seats_big_box {
    align-items: end;
  }
  .addres_filter .travel_infos .child_seats_big_box .child_seats_box {
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
@media screen and (max-width: 376px) {
  .addres_filter .travel_infos .child_seats_big_box .child_seats_box {
    height: 350px;
  }
}
@media screen and (max-width: 320px) {
  .addres_filter .travel_infos .child_seats_big_box .child_seats_box {
    height: 400px;
  }
}
.addres_filter .seo_text_second {
  font-size: 15.7px;
}
.addres_filter .user_name_table {
  position: relative;
  margin-top: 15px;
}
.addres_filter .user_name_table img {
  position: absolute;
  margin-top: 1%;
  left: 7px;
  width: 24px;
}
.addres_filter .user_name_table .user_name_table_image {
  margin-top: 5%;
}
.addres_filter .user_name_table input {
  padding-left: 35px;
}
.addres_filter .user_name_table textarea {
  width: 100%;
  min-height: 65px;
  padding-top: 10px;
  padding-left: 45px;
  border: none;
  outline: none;
}
.addres_filter .user_name_table svg {
  position: absolute;
  margin-top: 1.5%;
  left: 7px;
  color: #000;
  font-size: 22px;
}

.car_clases_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.car_clases_box .car_class {
  display: flex;
  align-items: center;
  width: 49%;
  height: 40px;
  margin-bottom: 25px;
  padding-left: 10px;
  border: 1px solid #000;
  border-radius: 50px;
  cursor: pointer;
}
.car_clases_box .car_class img {
  width: 80px;
  height: 30px;
}
.car_clases_box .car_class .title_icons {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
.car_clases_box .car_class .title_icons span {
  margin-bottom: 5px;
  font-size: 12px;
}
@media screen and (max-width: 768px) {
  .car_clases_box {
    display: block;
  }
  .car_clases_box .car_class {
    width: 100%;
  }
}
.car_clases_box .selected_car {
  border: 2px solid red;
}

.return_route_parent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 15px;
}
.return_route_parent #return_route {
  margin-right: 10px;
  width: 20px;
  height: 25px;
}

div.leaflet-control-attribution.leaflet-control {
  display: none;
}

.book_now_btn {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.book_now_btn button {
  background-color: #21ba45;
  color: #fff;
  padding: 5px 15px;
  font-size: 17px;
  border: 2px solid #21ba45;
  border-radius: 50px;
  transition: all 0.7s ease;
}
.book_now_btn button:hover {
  background-color: transparent;
  color: #000;
}

.pac-container .pac-item {
  padding: 10px !important;
  font-size: 16px !important;
}

.distance-info {
  color: red;
}
.distance-info span {
  color: #26444F;
  font-weight: 600;
}

.mt-11 {
  margin-top: 110px;
}/*# sourceMappingURL=bookNow.css.map */