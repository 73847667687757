.card {
  background: none !important;
  height: 400px !important;
  border: none !important;
  border-radius: 15px !important;
  box-shadow: 0px 0px 15px 10px rgba(128, 128, 128, 0.289);
  transition: transform 0.7s ease;
}
.card .card-body {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.card .card-body a {
  display: flex;
  justify-content: end;
}
.card img {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 225px;
}
.card:hover {
  transform: scale(1.05);
}
@media screen and (max-width: 1200px) {
  .card {
    height: auto !important;
  }
  .card img {
    height: auto;
  }
}/*# sourceMappingURL=myCard.css.map */