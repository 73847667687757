.add_review_page{
    text-align: center;
    h2{
        color: #26444F;
        span{
            font-size: 22px;
            @media screen and (max-width:320px) {
                font-size: 20px;
            }
        }
    }
    textarea{
        width: 50% !important;
        min-height: 80px;
        margin-bottom: 30px;
        padding: 10px;
        border: none;
        border-radius: 7px;
        outline: none;
        @media screen and (max-width:768px) {
            width: 80% !important;
        }
    }
    select{
        width: 100px;
    }
    button{
        background-color: #26444F;
        margin: 30px 0;
        padding: 5px 15px;
        color: #fff;
        border: 2px solid #26444F;
        border-radius: 7px;
        cursor: pointer;
        transition: all 0.7s ease-in;
        &:hover{
            background-color: #e9e9e9;
            color: #000;
        }
    }
    #select_image{
        background-color: #26444F;
        margin: 0 auto;
        margin-bottom: 50px;
        padding: 5px 15px;
        color: #fff;
        border: 2px solid #26444F;
        border-radius: 7px;
        cursor: pointer;
        transition: all 0.7s ease-in;
        &:hover{
            background-color: #e9e9e9;
            color: #000;
        }
    }
    #for_image{
        margin: 0 auto;
        padding: 0 10px;
        img{
            width: 400px;
            height: 300px;
            border-radius: 7px;
            @media screen and (max-width:576px) {
                width: 100%;
            }
        }
    }
    ul{
        width: fit-content;
        margin: 0 auto;
    }
}