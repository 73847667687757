.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  color: #26444F;
}
.footer a {
  color: #26444F;
  font-family: serif;
  font-weight: bold;
}
@media screen and (max-width: 503.5px) {
  .footer {
    font-size: 12px;
  }
}
@media screen and (max-width: 365px) {
  .footer {
    font-size: 10px;
  }
}/*# sourceMappingURL=footer.css.map */