.my_calendar{
    color: #000;
    padding: 0 !important;
    div{
        text-align: center;
    } 
    select{
        margin: 5px;
        padding: 5px;
        border-radius: 7px;
        font-size: 16px;
    }
    .selected_date{
        position: relative;
        background-color: #fff;
        width: 100%;
        padding: 8px 0;
        cursor: pointer;
        .date_arrow_down{
            position: absolute !important;
            top: 8px !important;
            left: 95% !important;
            padding: 0;
        }
    }
    .date_calendar{
        position: relative;
        background-color: #fff;
        width: 60%;
        margin: 0 auto;
        margin-top: 30px;
        border-radius: 15px;
        .days{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            div{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                margin-left: 3px;
                padding: 0;
                border-radius: 50%;
                cursor: pointer;
                transition: all 0.3s ease;
                &:hover{
                    background-color: #26444F;
                    color: #fff;
                }
            }
            .selected_day{
                background-color: #26444F;
                color: #fff;
            }
        }
        .keter{
            font-size: 22px;
        }
        .date_calendar_close{
            position: absolute !important;
            top: 85% !important;
            left: 73% !important;
            color: blue;
            cursor: pointer;
            user-select: none;
        }
        @media screen and (max-width:768px) {
            width: 80%;
        }
        @media screen and (max-width:576px) {
            width: 100%;
        }
    }
    .date_menu{
        position: absolute !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        background-color: #fff;
        width: 100%;
        height: 100%;
        border-radius: 15px;
        overflow-y: scroll;
        z-index: 1;
        div{
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            cursor: pointer;
            transition: all 0.3s ease;
            &:hover{
                background-color: #26444F;
                color: #fff;
            }
        }
        .selected_year{
            background-color: #26444F;
            color: #fff;
        }
    }
    .date_menu.month{
        overflow-y: auto;
        div{
            height: fit-content;
            border-radius: 15px;
        }
    }
    .date_menu.hour{
        display: block;
        div{
            width: fit-content;
            margin: 0 auto;
            margin-bottom: 5px;
            border-radius: 15px;
        }
    }
}