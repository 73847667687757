.excursion_home_item{
    display: flex;
    align-items: center;
    color: #000;
    padding: 15px;
    border-radius: 7px;
    box-shadow: 0px 0px 30px 7px #0000009c;
    .excursion_img{    
        margin-right: 30px;
        img{
            max-width: 350px;
            min-width: 350px;
            border-radius: 7px;
            @media screen and (max-width:768px) {
                width: 100%;
                max-width: none;
                min-width: auto;
            }
        }
    }
    h5{
        color: #26444F;
        text-decoration: underline;
    }
    @media screen and (max-width:992px) {
        display: block;
        .excursion_img{
            margin-right: 0;
            margin-bottom: 15px;
        }
    }
}