.add_review{
    display: flex;
    justify-content: end;
    a{
        background-color: #26444F;
        color: #fff;
        padding: 5px 15px;
        border: 2px solid #26444F;
        border-radius: 50px;
        cursor: pointer;
        transition: all 0.7s ease;
        &:hover{
            background-color: transparent;
            color: #000;
        }
    }
}
.review_contents{
    display: flex;
    justify-content: space-between;
    border: 1px solid silver;
    border-radius: 7px;
    padding: 0 !important;
    img{
        width: 200px;
        min-height: 150px;
        max-height: 250px;
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
    }
    .review_box{
        display: flex;
        justify-content: space-between;
        width: 100%;
        min-height: 35px;
        padding: 5px 10px;
        border-radius: 7px;
        .stars{
            display: flex;
            margin-top: -3px;
            font-size: 18px;
        }
        .active{
            color: gold;
        }
    }
    @media screen and (max-width:992px) {
        display: block;
        img{
            width: 100%;
            min-height: 0;
            max-height: 100%;
            height: 300px;
            border-bottom-left-radius: 0;
            border-top-right-radius: 7px;
        }
    }
}
#page_numes_all{
    display: flex;
    justify-content: center;
    margin-top: 50px;
    .page_num{
        background-color: #26444F;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        margin-right: 15px;
        color: #fff;
        border-radius: 50%;
        cursor: pointer;
        user-select: none;
    }
    .page_selected_num{
        background-color: blue;
    }
}