.contacts{
    display: flex;
    justify-content: space-around;
    text-align: center;
    .contact_block{
        .contact_icon{
            color: #26444F;
            font-size: 32px;
            margin-bottom: 5px;
            img{
                width: 32px;
                height: 25px;
            }
        }
    }
    @media screen and (max-width:768px) {
        display: block;
        .contact_block{
            margin-top: 30px;
        }
    }
}