.error{
    padding-top: 150px;
    color: red;
    text-align: center;
    font-size: 100px;
    user-select: none;
    div{
        color: #26444F;
        font-size: 48px;
    }
}