body{
    background-color: #e9e9e9;
    margin: 0;
    padding: 0;
    color: #000;
    font-family: serif;
    &::-webkit-scrollbar{
        background-color: silver;
        width: 15px;
    }
    &::-webkit-scrollbar-thumb{
        background-color: gray;
        border-radius: 15px;
    }
}
.content{
    min-height: calc(100vh - 150px);
}
#first_tag_a{
    position: absolute;
    opacity: 0;
    z-index: -10;
}
a{
    text-decoration: none;
}
h2{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.seo_text{
    color: #343232;
    font-size: 14.3px;
}
.mt-6{
    margin-top: 60px;
}
.mt-10{
    margin-top: 100px;
}
.mt-15{
    margin-top: 150px;
}
.mb-6{
    margin-bottom: 60px;
}
.link_a{
    color: #26444F;
    text-decoration: underline;
}