.excursion_home_item {
  display: flex;
  align-items: center;
  color: #000;
  padding: 15px;
  border-radius: 7px;
  box-shadow: 0px 0px 30px 7px rgba(0, 0, 0, 0.6117647059);
}
.excursion_home_item .excursion_img {
  margin-right: 30px;
}
.excursion_home_item .excursion_img img {
  max-width: 350px;
  min-width: 350px;
  border-radius: 7px;
}
@media screen and (max-width: 768px) {
  .excursion_home_item .excursion_img img {
    width: 100%;
    max-width: none;
    min-width: auto;
  }
}
.excursion_home_item h5 {
  color: #26444F;
  text-decoration: underline;
}
@media screen and (max-width: 992px) {
  .excursion_home_item {
    display: block;
  }
  .excursion_home_item .excursion_img {
    margin-right: 0;
    margin-bottom: 15px;
  }
}/*# sourceMappingURL=excursions.css.map */