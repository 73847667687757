.page_title_cur {
  color: #26444F;
  cursor: pointer;
}

.individual_item {
  position: relative;
}
.individual_item .individual_photo_title {
  position: absolute;
  top: 0;
  left: 0;
}
.individual_item .individual_photo_title img {
  width: 250px;
  height: 150px;
}
@media screen and (max-width: 992px) {
  .individual_item .individual_photo_title {
    position: relative;
  }
  .individual_item .individual_photo_title img {
    width: 100%;
    height: 250px;
  }
}
.individual_item .accordion-button {
  background-color: transparent !important;
  min-height: 150px;
  color: #26444F !important;
  padding-left: 50%;
  box-shadow: none !important;
}
@media screen and (max-width: 992px) {
  .individual_item .accordion-button {
    min-height: auto;
    padding-left: 10px;
  }
}

.transport_service_prices {
  font-size: 24px;
}
@media screen and (max-width: 768px) {
  .transport_service_prices {
    font-size: 20px;
  }
}
@media screen and (max-width: 576px) {
  .transport_service_prices {
    font-size: 18px;
  }
}

.transfer_table .transfer_title {
  background-color: #26444F;
  color: rgb(232, 200, 20);
  font-size: 18px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .transfer_table .transfer_title {
    font-size: 16px;
  }
}
.transfer_table table {
  width: 100%;
  text-align: center;
  border: 2px solid rgb(232, 200, 20);
  border-bottom: none;
}
.transfer_table tr {
  border-bottom: 2px solid rgb(232, 200, 20);
}
.transfer_table tr th {
  background-color: rgb(232, 200, 20);
  color: #26444F;
}
.transfer_table tr th, .transfer_table tr td {
  padding-left: 5px;
  border-left: 2px solid rgb(232, 200, 20);
}

.hot_offer {
  border: 2px solid red;
  padding: 5px;
  padding-top: 0;
}
.hot_offer .hot_text {
  color: red;
  margin-right: 10px;
  text-align: end;
  font-weight: 600;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

#exchanger {
  text-align: center;
}
#exchanger select {
  height: 30px;
  margin: 0 30px;
}
#exchanger button {
  border: 2px solid #26444F;
  background-color: #26444F;
  color: #fff;
  min-width: 100px;
  padding: 3px 10px;
  border-radius: 7px;
  transition: 0.7s ease;
}
#exchanger button:hover {
  background-color: #fff;
  color: #000;
}
#exchanger br {
  display: none;
}
@media screen and (max-width: 576px) {
  #exchanger br {
    display: block;
  }
}/*# sourceMappingURL=individualTransportation.css.map */