.my_calendar {
  color: #000;
  padding: 0 !important;
}
.my_calendar div {
  text-align: center;
}
.my_calendar select {
  margin: 5px;
  padding: 5px;
  border-radius: 7px;
  font-size: 16px;
}
.my_calendar .selected_date {
  position: relative;
  background-color: #fff;
  width: 100%;
  padding: 8px 0;
  cursor: pointer;
}
.my_calendar .selected_date .date_arrow_down {
  position: absolute !important;
  top: 8px !important;
  left: 95% !important;
  padding: 0;
}
.my_calendar .date_calendar {
  position: relative;
  background-color: #fff;
  width: 60%;
  margin: 0 auto;
  margin-top: 30px;
  border-radius: 15px;
}
.my_calendar .date_calendar .days {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.my_calendar .date_calendar .days div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-left: 3px;
  padding: 0;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}
.my_calendar .date_calendar .days div:hover {
  background-color: #26444F;
  color: #fff;
}
.my_calendar .date_calendar .days .selected_day {
  background-color: #26444F;
  color: #fff;
}
.my_calendar .date_calendar .keter {
  font-size: 22px;
}
.my_calendar .date_calendar .date_calendar_close {
  position: absolute !important;
  top: 85% !important;
  left: 73% !important;
  color: blue;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
@media screen and (max-width: 768px) {
  .my_calendar .date_calendar {
    width: 80%;
  }
}
@media screen and (max-width: 576px) {
  .my_calendar .date_calendar {
    width: 100%;
  }
}
.my_calendar .date_menu {
  position: absolute !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  overflow-y: scroll;
  z-index: 1;
}
.my_calendar .date_menu div {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}
.my_calendar .date_menu div:hover {
  background-color: #26444F;
  color: #fff;
}
.my_calendar .date_menu .selected_year {
  background-color: #26444F;
  color: #fff;
}
.my_calendar .date_menu.month {
  overflow-y: auto;
}
.my_calendar .date_menu.month div {
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 15px;
}
.my_calendar .date_menu.hour {
  display: block;
}
.my_calendar .date_menu.hour div {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 5px;
  border-radius: 15px;
}/*# sourceMappingURL=calendar.css.map */