#input_map{
    display: flex;
    justify-content: space-between;
    font-family: -apple-system,Helvetica Neue,Helvetica,Arial,sans-serif;
    #map_parent{
        width: 50%;
        #map{
            width: 100%;
            height: 400px;
            outline: none;
            z-index: 0;
            @media screen and (max-width:1200px) {
                margin-bottom: 30px;
            }
        }
    }
    @media screen and (max-width:1199px) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        #map_parent{
            width: 100%;
        }
    }
}
.addres_filter{
    width: 49%;
    div{
        div{
            position: relative !important;
            top: 0 !important;
            left: 0 !important;
            padding: 10px;
        }
    }
    input{
        width: 100%;
        height: 40px;
        padding-left: 10px;
        border: none;
        outline: none;
    }
    .input_clear{
        position: relative;
        .clear_filter{
            position: absolute !important;
            background-color: silver;
            top: 0 !important;
            left: 95.2% !important;
            width: fit-content;
            max-height: 39.5px;
            height: 100%;
            color: gray;
            cursor: pointer;
            @media screen and (max-width:1199px) {
                left: 97% !important;
            }
            @media screen and (max-width:992px) {
                left: 95.5% !important;
            }
            @media screen and (max-width:768px) {
                left: 94% !important;
            }
        }
        div{
            width: 100%;
            div{
                min-width: 100% !important;
            }
        }
        .filter_results{
            background-color: #fff;
            cursor: pointer;
            transition: all 0.7s ease;
            &:hover{
                background-color: silver;
            }
        }
    }
    @media screen and (max-width:1199px) {
        width: 100%;
    }
    .travel_infos{
        margin: 0 auto;
        color: gray;
        #date_time_travel,#date_time_return{
            background-color: #fff !important;
        }
        input{
            cursor: pointer;
        }
        .travel_info_icons{
            position: relative;
            margin-top: 15px;
            cursor: pointer;
            input{
                text-align: center;
            }
            .travel_info_icons_left{
                position: absolute !important;
                display: flex;
                align-items: center;
                width: fit-content;
                top: 7px !important;
                left: 10px !important;
                svg{
                    margin-right: 10px;
                    color: #000;
                }
            }
            .travel_info_icons_right{
                position: absolute !important;
                display: flex;
                align-items: center;
                width: fit-content;
                top: 5px !important;
                left: 80% !important;
                span{
                    background-color: #26444F;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 30px;
                    height: 30px;
                    color: #fff;
                    font-size: 24px;
                    border-radius: 50%;
                    user-select: none;
                    &:first-child{
                        margin-right: 10px;
                        padding-bottom: 5px;
                    }
                }
                @media screen and (max-width:576px) {
                    left: 70% !important;
                }
            }
        }
        .child_seats{
            display: flex;
            background-color: #fff;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;
            cursor: pointer;
            div{
                svg{
                    color: #000;
                    &:first-child{
                        margin-right: 10px;
                    }
                }
            }
            .selected_childs_seats{
                color: #000;
                span{
                    margin-left: 5px;
                    padding: 5px;
                    padding-right: 8px;
                    padding-bottom: 8px;
                    border: 1px solid silver;
                    border-radius: 50px;
                    img{
                        width: 25px;
                        height: 25px;
                    }
                    @media screen and (max-width:320px) {
                        margin-left: 3px;
                    }
                }
            }
        }
        .child_seats_big_box{
            position: fixed !important;
            background-color: #00000092;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0 !important;
            width: 100%;
            height: 100vh !important;
            padding: 0;
            z-index: 1;
            .child_seats_box{
                background-color: #fff;
                width: 400px;
                height: 300px;
                color: #000;
                padding: 0 25px;
                padding-top: 15px;
                border-radius: 10px;
                z-index: 2;
                .child_seats_item{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0;
                    .child_seats_close{
                        font-size: 22px;
                        padding: 0;
                        &:last-child{
                            cursor: pointer;
                        }
                    }
                    .child_seats_two_item{
                        display: flex;
                        align-items: center;
                        padding: 0;
                        .child_seats_title{
                            font-weight: 600;
                        }
                        .child_seats_buttons{
                            display: flex;
                            align-items: center;
                            width: fit-content;
                        span{
                            background-color: #26444F;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 30px;
                            height: 30px;
                            color: #fff;
                            font-size: 24px;
                            border-radius: 50%;
                            cursor: pointer;
                            user-select: none;
                            &:first-child{
                                padding-bottom: 5px;
                            }
                        }
                        input{
                            width: 50px;
                            margin: 0 5px;
                            text-align: center;
                            padding: 0;
                            border: 1px solid gray;
                            border-radius: 7px;
                            cursor: default;
                        }
                        }
                        &:first-child{
                            margin-top: 10px;
                        }
                    }
                }
            }
            @media screen and (max-width:768px) {
                align-items: end;
                .child_seats_box{
                    width: 100%;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
            @media screen and (max-width:376px) {
                .child_seats_box{
                    height: 350px;
                }
            }
            @media screen and (max-width:320px) {
                .child_seats_box{
                    height: 400px;
                }
            }
        }
    }
    .seo_text_second{
        font-size: 15.7px;
    }
    .user_name_table{
        position: relative;
        margin-top: 15px;
        img{
            position: absolute;
            margin-top: 1%;
            left: 7px;
            width: 24px;
        }
        .user_name_table_image{
            margin-top: 5%;
        }
        input{
            padding-left: 35px;
        }
        textarea{
            width: 100%;
            min-height: 65px;
            padding-top: 10px;
            padding-left: 45px;
            border: none;
            outline: none;
        }
        svg{
            position: absolute;
            margin-top: 1.5%;
            left: 7px;
            color: #000;
            font-size: 22px;
        }
    }
}
.car_clases_box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .car_class{
        display: flex;
        align-items: center;
        width: 49%;
        height: 40px;
        margin-bottom: 25px;
        padding-left: 10px;
        border: 1px solid #000;
        border-radius: 50px;
        cursor: pointer;
        img{
            width: 80px;
            height: 30px;
        }
        .title_icons{
            display: flex;
            flex-direction: column;
            font-size: 14px;
            span{
                margin-bottom: 5px;
                font-size: 12px;
            }
        }
    }
    @media screen and (max-width:768px) {
        display: block;
        .car_class{
            width: 100%;
        }
    }
    .selected_car{
        border: 2px solid red;
    }
}
.return_route_parent{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 15px;
    #return_route{
        margin-right: 10px;
        width: 20px;
        height: 25px;
    }
}
div.leaflet-control-attribution.leaflet-control{
    display: none;
}
.book_now_btn{
    display: flex;
    justify-content: center;
    margin-top: 30px;
    button{
        background-color: #21ba45;
        color: #fff;
        padding: 5px 15px;
        font-size: 17px;
        border: 2px solid #21ba45;
        border-radius: 50px;
        transition: all 0.7s ease;
        &:hover{
            background-color: transparent;
            color: #000;
        }
    }
}
.pac-container{
    .pac-item{
        padding: 10px !important;
        font-size: 16px !important;
    }
}
.distance-info{
    color: red;
    span{
        color: #26444F;
        font-weight: 600;
    }
}
.mt-11{
    margin-top: 110px;
}