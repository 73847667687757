#header{
    position: fixed;
    background-color: #e9e9e9;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 60px;
    padding: 0 !important;
    z-index: 1001;
    .navbar-brand{
        display: flex;
        align-items: center;
        width: 120px;
        height: 60px;
        margin: 0;
        img{
            width: 100%;
            height: 100%;
        }
        @media screen and (max-width:1199px) {
            width: 200px;
        }
    }
    #nav_toogler{
        box-shadow: none;
        &:not(.collapsed){
            background-color: #cfe2ff;
        }
    }
    #basic-nav-dropdown{
        img{
            width: auto;
            height: auto;
        }
    }
    .dropdown-menu{
        background-color: #e9e9e9;
        border: none;
        img{
            width: auto;
            height: auto;
        }
        @media screen and (min-width:1199px) {
            margin-left: -50px;
        }
    }
    .header_armenian_font{
        @media screen and (min-width: 1199px) {
            font-size: 14px;
        }
    }
    .nav_links{
        min-height: 60px;
        a{
            display: flex;
            align-items: center;
            margin-left: 10px;
        }
        @media screen and (max-width:1199px) {
            padding: 15px 0;
            a{
                margin-left: 0;
                margin-top: 10px;
            }
        }
    }
    @media screen and (max-width:1199px) {
        padding: 15px 0;
    }
    *{
        color: #26444F;
    }
}
.navbar-collapse.show{
    padding-bottom: 10px !important;
}
#to_order{
    position: fixed;
        top: 65px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1002;
        background-color: #26444F;
        width: fit-content;
        padding: 5px 30px;
        border-radius: 50px;
        user-select: none;
        a{
            color: #fff;
            font-size: 17px;
            font-family: Georgia, 'Times New Roman', Times, serif;
        }
    @media screen and (max-width:576px) {
        top: 12.5px;
        margin-left: 15%;
    }
    @media screen and (max-width:375.2px) {
        padding: 5px 20px;
    }
    @media screen and (max-width:320px) {
        top: 65px;
        margin-left: 0;
    }
}