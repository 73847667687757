.error {
  padding-top: 150px;
  color: red;
  text-align: center;
  font-size: 100px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.error div {
  color: #26444F;
  font-size: 48px;
}/*# sourceMappingURL=error.css.map */